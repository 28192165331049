<template>
    <div class="page-content white-bg">
        <NewsGuide :typeId="typeId" :id="id" />
    </div>
</template>
<script>
export default {
    name: "mediation-center",
    data() {
        return {
            typeId: "10000058",
            id: this.$route.query.id || "",
        };
    },
};
</script>
<style lang="less" scoped>
</style>
